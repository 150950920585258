<template>
  <div>
    <div style="margin: 10px 0">
      <el-date-picker
          v-model="startDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择起始日期"
      ></el-date-picker>
      <el-date-picker
          v-model="endDate"
          type="date"
          format="yyyy-MM-dd"
          placeholder="选择截止日期"
      ></el-date-picker>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart2" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart3" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart4" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart5" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart6" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart7" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart8" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart9" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart10" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart11" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart12" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart13" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart14" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart15" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart16" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart17" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart18" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart19" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart20" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart21" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart22" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart23" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart24" class="chart"></div>
      </el-card>
    </div>
    <div class="chart-container">
      <el-card style="width: 49%">
        <div ref="lineChart25" class="chart"></div>
      </el-card>
      <el-card style="width: 49%">
        <div ref="lineChart26" class="chart"></div>
      </el-card>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Allcost",
  data() {
    return {
      form: {},
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      day: '',
      startDate: "",
      endDate: "",
      allSum: 0,
      allPirce: 0,
      totalData: [],
      lineHourNum: [],
    }
  },
  created() {
    // 获取当前日期
    const currentDate = new Date();

    // 设置 endDate 为当前时间
    this.endDate = currentDate.toISOString();

    // 设置 startDate 为七天前的时间
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    this.startDate = sevenDaysAgo.toISOString();

    // 调用加载方法
    this.load();
  },

  // mounted() {
  //   this.renderLineChart();
  //   this.renderLineChart2();
  // },
  methods: {
    load() {
      this.request.get("/failureRecord/getAllLineHourNum", {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(res => {
        this.totalData = res.data;
      });
      this.request.get("/failureRecord/getLineHourNum", {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(res => {
        this.lineHourNum = res.data;
      });
      this.renderLineChart();
      this.renderLineChart2();
      this.renderLineChart3();
      this.renderLineChart4();
      this.renderLineChart5();
      this.renderLineChart6();
      this.renderLineChart7();
      this.renderLineChart8();
      this.renderLineChart9();
      this.renderLineChart10();
      this.renderLineChart11();
      this.renderLineChart12();
      this.renderLineChart13();
      this.renderLineChart14();
      this.renderLineChart15();
      this.renderLineChart16();
      this.renderLineChart17();
      this.renderLineChart18();
      this.renderLineChart19();
      this.renderLineChart20();
      this.renderLineChart21();
      this.renderLineChart22();
      this.renderLineChart23();
      this.renderLineChart24();
      this.renderLineChart25();
      this.renderLineChart26();

    },
    reset(){
      this.startDate = ""
      this.endDate = ""
      this.load()
    },
    // 渲染折线图
    renderLineChart() {
      this.request.get("/failureRecord/filterDateSum", {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(res => {
        this.allSum = res.data;
      });

      this.request.get('/failureRecord/filterDate', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.count);

          const lineChart = echarts.init(this.$refs.lineChart);
          const option = {
            title: {
              text: '工单数:'+this.allSum
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => Math.floor(value)
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'    // 格式化标签内容为坐标值
              }
            }]
          };
          lineChart.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart2() {
      this.request.get('/recordComponent/filterDate', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.price);
          this.allPrice = daypro[0].allPrice
          const lineChart2 = echarts.init(this.$refs.lineChart2);
          const option = {
            title: {
              text: '备件花费:'+ this.allPrice + '元'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => `${Math.floor(value)}元`
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'    // 格式化标签内容为坐标值
              }
            }]
          };
          lineChart2.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart3() {
      this.request.get('/failureRecord/getRecordType', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.rate);

          const lineChart3 = echarts.init(this.$refs.lineChart3);
          const option = {
            title: {
              text: '应急维修率'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => `${Math.floor(value)}%`  // 在 Y 轴标签后添加百分号
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}%'   // 格式化标签内容为坐标值并添加百分号
              }
            }]
          };
          lineChart3.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart4() {
      this.request.get('/failureRecord/getPMYield', {
        params: {
          start: this.startDate,
          end: this.endDate
        }
      }).then(response => {
        const daypro = response.data;

        if (daypro && Array.isArray(daypro)) {
          const dates = daypro.map(item => item.day);
          const counts = daypro.map(item => item.rate);

          const lineChart4 = echarts.init(this.$refs.lineChart4);
          const option = {
            title: {
              text: 'PM Yield'
            },
            xAxis: {
              type: 'category',
              data: dates
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                formatter: (value) => Math.floor(value)  // 在 Y 轴标签后添加百分号
              },
              minInterval: 1
            },
            series: [{
              data: counts,
              type: 'line',
              label: {
                show: true,         // 显示标签
                position: 'top',    // 标签显示在折线点上方
                formatter: '{c}'   // 格式化标签内容为坐标值并添加百分号
              }
            }]
          };
          lineChart4.setOption(option);
        } else {
          console.error('Unexpected data format:', daypro);
        }
      }).catch(error => {
        console.error('Failed to fetch data:', error);
      });
    },
    renderLineChart5() {
      // 提取数据
      const types = this.totalData.map(item => item.type);
      const counts = this.totalData.map(item => item.count);
      const workHours = this.totalData.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));


      const myChart = echarts.init(this.$refs.lineChart5);
      const option = {
        title: {
          text: `故障记录统计 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['数量', '工时']
          },
          xAxis: {
            type: 'category',
            data: types
          },
          yAxis: [
            {
              type: 'value',
              name: '数量',
              min: 0,
              position: 'left'
            },
            {
              type: 'value',
              name: '工时',
              min: 0,
              position: 'right'
            }
          ],
          series: [
            {
              name: '数量',
              type: 'bar',
              data: counts,
              itemStyle: {
                color: '#42a5f5' // 柱子颜色
              }
            },
            {
              name: '工时',
              type: 'line',
              yAxisIndex: 1,
              data: workHours,
              itemStyle: {
                color: '#ff7043' // 线条颜色
              },
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        };
        myChart.setOption(option);
    },
    renderLineChart6() {
      // 提取数据
      const dian1 = this.lineHourNum.filter(item => item.lineId === 1);
      const types = dian1.map(item => item.type);
      const counts = dian1.map(item => item.count);
      const workHours = dian1.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart6);
      const option = {
        title: {
          text: `电镀一线 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart7() {
      // 提取数据
      const dian2 = this.lineHourNum.filter(item => item.lineId === 4);
      const types = dian2.map(item => item.type);
      const counts = dian2.map(item => item.count);
      const workHours = dian2.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart7);
      const option = {
        title: {
          text: `电镀二线 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart8() {
      // 提取数据
      const dian3 = this.lineHourNum.filter(item => item.lineId === 5);
      const types = dian3.map(item => item.type);
      const counts = dian3.map(item => item.count);
      const workHours = dian3.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart8);
      const option = {
        title: {
          text: `电镀三线 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart9() {
      // 提取数据
      const zhuzao = this.lineHourNum.filter(item => item.lineId === 6);
      const types = zhuzao.map(item => item.type);
      const counts = zhuzao.map(item => item.count);
      const workHours = zhuzao.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart9);
      const option = {
        title: {
          text: `铸造 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart10() {
      // 提取数据
      const yazhu = this.lineHourNum.filter(item => item.lineId === 7);
      const types = yazhu.map(item => item.type);
      const counts = yazhu.map(item => item.count);
      const workHours = yazhu.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart10);
      const option = {
        title: {
          text: `压铸 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart11() {
      // 提取数据
      const jijia = this.lineHourNum.filter(item => item.lineId === 8);
      const types = jijia.map(item => item.type);
      const counts = jijia.map(item => item.count);
      const workHours = jijia.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart11);
      const option = {
        title: {
          text: `机加 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart12() {
      // 提取数据
      const gongmo = this.lineHourNum.filter(item => item.lineId === 9);
      const types = gongmo.map(item => item.type);
      const counts = gongmo.map(item => item.count);
      const workHours = gongmo.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart12);
      const option = {
        title: {
          text: `工模 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart13() {
      // 提取数据
      const yanmo = this.lineHourNum.filter(item => item.lineId === 10);
      const types = yanmo.map(item => item.type);
      const counts = yanmo.map(item => item.count);
      const workHours = yanmo.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart13);
      const option = {
        title: {
          text: `研磨 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart14() {
      // 提取数据
      const tuzhuang = this.lineHourNum.filter(item => item.lineId === 11);
      const types = tuzhuang.map(item => item.type);
      const counts = tuzhuang.map(item => item.count);
      const workHours = tuzhuang.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart14);
      const option = {
        title: {
          text: `涂装 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart15() {
      // 提取数据
      const QA = this.lineHourNum.filter(item => item.lineId === 12);
      const types = QA.map(item => item.type);
      const counts = QA.map(item => item.count);
      const workHours = QA.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart15);
      const option = {
        title: {
          text: `QA品保 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart16() {
      // 提取数据
      const wushui = this.lineHourNum.filter(item => item.lineId === 14);
      const types = wushui.map(item => item.type);
      const counts = wushui.map(item => item.count);
      const workHours = wushui.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart16);
      const option = {
        title: {
          text: `生活污水 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart17() {
      // 提取数据
      const PVD = this.lineHourNum.filter(item => item.lineId === 15);
      const types = PVD.map(item => item.type);
      const counts = PVD.map(item => item.count);
      const workHours = PVD.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart17);
      const option = {
        title: {
          text: `PVD (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart18() {
      // 提取数据
      const zhusu = this.lineHourNum.filter(item => item.lineId === 16);
      const types = zhusu.map(item => item.type);
      const counts = zhusu.map(item => item.count);
      const workHours = zhusu.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart18);
      const option = {
        title: {
          text: `注塑 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart19() {
      // 提取数据
      const sheshi = this.lineHourNum.filter(item => item.lineId === 18);
      const types = sheshi.map(item => item.type);
      const counts = sheshi.map(item => item.count);
      const workHours = sheshi.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart19);
      const option = {
        title: {
          text: `设施 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart20() {
      // 提取数据
      const liku = this.lineHourNum.filter(item => item.lineId === 19);
      const types = liku.map(item => item.type);
      const counts = liku.map(item => item.count);
      const workHours = liku.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart20);
      const option = {
        title: {
          text: `立库 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart21() {
      // 提取数据
      const zuzhuang = this.lineHourNum.filter(item => item.lineId === 30);
      const types = zuzhuang.map(item => item.type);
      const counts = zuzhuang.map(item => item.count);
      const workHours = zuzhuang.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart21);
      const option = {
        title: {
          text: `组装 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart22() {
      // 提取数据
      const huasa = this.lineHourNum.filter(item => item.lineId === 31);
      const types = huasa.map(item => item.type);
      const counts = huasa.map(item => item.count);
      const workHours = huasa.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart22);
      const option = {
        title: {
          text: `花洒组装 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart23() {
      // 提取数据
      const QAzuzhuang = this.lineHourNum.filter(item => item.lineId === 32);
      const types = QAzuzhuang.map(item => item.type);
      const counts = QAzuzhuang.map(item => item.count);
      const workHours = QAzuzhuang.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart23);
      const option = {
        title: {
          text: `QA组装 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart24() {
      // 提取数据
      const xinzheng = this.lineHourNum.filter(item => item.lineId === 33);
      const types = xinzheng.map(item => item.type);
      const counts = xinzheng.map(item => item.count);
      const workHours = xinzheng.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart24);
      const option = {
        title: {
          text: `行政楼 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart25() {
      // 提取数据
      const wushuichuli = this.lineHourNum.filter(item => item.lineId === 34);
      const types = wushuichuli.map(item => item.type);
      const counts = wushuichuli.map(item => item.count);
      const workHours = wushuichuli.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart25);
      const option = {
        title: {
          text: `污水处理 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    renderLineChart26() {
      // 提取数据
      const cangku = this.lineHourNum.filter(item => item.lineId === 35);
      const types = cangku.map(item => item.type);
      const counts = cangku.map(item => item.count);
      const workHours = cangku.map(item => item.workHour);
      const totalCount = counts.reduce((a, b) => a + b, 0);
      const totalWorkHours = Math.round(workHours.reduce((a, b) => a + b, 0));
      // ECharts 配置
      const myChart = echarts.init(this.$refs.lineChart26);
      const option = {
        title: {
          text: `仓库 (工单: ${totalCount}, 工时: ${totalWorkHours})`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['数量', '工时']
        },
        xAxis: {
          type: 'category',
          data: types
        },
        yAxis: [
          {
            type: 'value',
            name: '数量',
            min: 0,
            position: 'left'
          },
          {
            type: 'value',
            name: '工时',
            min: 0,
            position: 'right'
          }
        ],
        series: [
          {
            name: '数量',
            type: 'bar',
            data: counts,
            itemStyle: {
              color: '#42a5f5' // 柱子颜色
            }
          },
          {
            name: '工时',
            type: 'line',
            yAxisIndex: 1,
            data: workHours,
            itemStyle: {
              color: '#ff7043' // 线条颜色
            },
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
  }
}
</script>
<style scoped>

.chart-container {
  display: flex;
  justify-content: space-between; /* 让两个卡片均匀分布 */
  align-items: flex-start; /* 让两个卡片顶部对齐 */
  width: 100%; /* 父容器宽度为100% */
}

.chart {
  width: 100%;
  height: 300px;
}

/*//.t_pagination_select {
//  .el-pagination {
//    display: flex;
//    background-color: #fff;
//    align-items: center;
//    .el-pagination__total,
//    .el-pager,
//    button {
//      display: flex;
//      align-items: center;
//    }
//  }
//}*/

</style>
